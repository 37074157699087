import * as React from 'react';

import {
  BsEnvelope,
  BsFacebook,
  BsInstagram,
  BsLinkedin,
  BsTwitter,
  BsYoutube,
} from 'react-icons/bs';

interface NavItemChild {
  label: string;
  href: string;
  icon?: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  isContact?: boolean;
}

export interface NavItem {
  label: string;
  children?: NavItemChild[];
  href?: string;
}

const commonNavItems: NavItem[] = [
  {
    label: 'Home',
    href: '/',
  },
  {
    label: 'Pricing',
    href: '/pricing',
  },
  // {
  //   label: 'Industries',
  //   children: [
  //     {
  //       label: 'For businesses',
  //       href: '/industries/businesses',
  //     },
  //     {
  //       label: 'For bookkeepers',
  //       href: '/industries/bookkeepers',
  //     },
  //   ],
  // },
  {
    label: 'Features',
    children: [
      {
        label: 'Employee onboarding',
        href: '/features/employee-onboarding',
      },
      {
        label: 'Client identity verification',
        href: '/features/client-identity-verification',
      },
      {
        label: 'Licence and certification management',
        href: '/features/licence-and-certification-management',
      },
      {
        label: 'Tax file number declaration',
        href: '/features/tax-file-number-declaration',
      },
      {
        label: 'Integrations',
        href: '/integrations/xero',
      },
    ],
  },
  {
    label: 'Resources',
    children: [
      {
        label: 'Support',
        href: '/support',
      },
      {
        label: 'Partnership',
        href: '/partner',
      },
    ],
  },
  // {
  //   label: 'Contact',
  //   href: '/contact',
  // },
];

export const headerNavItems: NavItem[] = [...commonNavItems];

export const footerNavItems: NavItem[] = [
  ...commonNavItems,
  {
    label: 'Socials',
    children: [
      {
        label: 'LinkedIn',
        href: 'https://www.linkedin.com/company/canyouappau',
        icon: BsLinkedin,
      },
      {
        label: 'YouTube',
        href: 'https://www.youtube.com/channel/UCZImavpuI8y9NEIp5xWxECA',
        icon: BsYoutube,
      },
      {
        label: 'Facebook',
        href: 'https://www.facebook.com/canyouappau',
        icon: BsFacebook,
      },
      {
        label: 'Instagram',
        href: 'https://www.instagram.com/canyouapp',
        icon: BsInstagram,
      },
      {
        label: 'Twitter',
        href: 'https://twitter.com/canyouappau',
        icon: BsTwitter,
      },
    ],
  },
  {
    label: 'Contact',
    children: [
      {
        label: 'hello@canyou.com.au',
        href: `${'mailto:hello@canyou.com.au'}`,
        icon: BsEnvelope,
        isContact: true,
      },
    ],
  },
  {
    label: 'Legal',
    children: [
      {
        label: 'End user license agreement',
        href: '/end-user-license-agreement',
      },
      {
        label: 'Privacy policy',
        href: '/privacy-policy',
      },
      {
        label: 'Terms and conditions',
        href: '/terms-and-conditions',
      },
      {
        label: 'Website terms of use',
        href: '/website-terms-of-use',
      },
    ],
  },
];
