import * as React from 'react';
import {
  Flex,
  Text,
  Stack,
  Collapse,
  Icon,
  Link,
  useDisclosure,
} from '@chakra-ui/react';
import { CgChevronDown } from 'react-icons/cg';
import { NavItem } from './navItems';

type MobileNavProps = {
  navItems: NavItem[];
};

export const MobileNav: React.FC<MobileNavProps> = ({ navItems }) => (
  <Stack bg="white" p={4} display={{ lg: 'none' }}>
    {navItems.map((navItem) => (
      <MobileNavItem key={navItem.label} {...navItem} />
    ))}
  </Stack>
);

const MobileNavItem = ({ label, children, href }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        href={href || '#'}
        justify="space-between"
        align="center"
        _hover={{
          textDecoration: 'none',
        }}
      >
        <Text fontWeight={600} color="brand.black">
          {label}
        </Text>
        {children && (
          <Icon
            as={CgChevronDown}
            transition="all .25s ease-in-out"
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle="solid"
          borderColor="gray.700"
          align="start"
        >
          {children &&
            children.map((child) => (
              <Link key={child.label} py={2} href={child.href}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};
