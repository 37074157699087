import * as React from 'react';
import {
  Box,
  Flex,
  Text,
  Stack,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
} from '@chakra-ui/react';
import { MdChevronRight } from 'react-icons/md';
import { NavItem } from './navItems';

type DesktopNavProps = {
  navItems: NavItem[];
};

export const DesktopNavItems: React.FC<DesktopNavProps> = ({ navItems }) => (
  <Stack
    direction="row"
    spacing={3}
    display={{ base: 'none', lg: 'flex' }}
    order="1"
  >
    {navItems.map(({ label, href, children }) => (
      <Box key={label}>
        <Popover trigger="hover" placement="bottom-start">
          <PopoverTrigger>
            <Link
              p={2}
              href={href || '#'}
              fontSize={{ base: '18px' }}
              fontWeight="500"
              _hover={{
                textDecoration: 'none',
                color: 'brand.blue',
              }}
            >
              {label}
            </Link>
          </PopoverTrigger>

          {children && (
            <PopoverContent
              border={0}
              boxShadow="xl"
              bg="white"
              p={4}
              rounded="xl"
              minW="sm"
            >
              <Stack>
                {children.map((child) => (
                  <DesktopSubNav key={child.label} {...child} />
                ))}
              </Stack>
            </PopoverContent>
          )}
        </Popover>
      </Box>
    ))}
  </Stack>
);

const DesktopSubNav: React.FC<NavItem> = ({ label, href }) => (
  <Link
    href={href}
    role="group"
    display="block"
    p={2}
    rounded="md"
    _hover={{ bg: 'blue.50' }}
  >
    <Stack direction="row" align="center">
      <Box>
        <Text
          transition="all .3s ease"
          _groupHover={{ color: 'brand.blue' }}
          fontWeight={500}
        >
          {label}
        </Text>
      </Box>
      <Flex
        transition="all .3s ease"
        transform="translateX(-10px)"
        opacity={0}
        _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
        justify="flex-end"
        align="center"
        flex={1}
      >
        <Icon color="brand.blue" w={5} h={5} as={MdChevronRight} />
      </Flex>
    </Stack>
  </Link>
);
