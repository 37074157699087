import * as React from 'react';
import {
  Flex,
  Collapse,
  useDisclosure,
  IconButton,
  Box,
} from '@chakra-ui/react';
import { MobileNav } from './MobileNav';
import { MdClose, MdMenu } from 'react-icons/md';
import { headerNavItems } from './navItems';
import { DesktopNav } from './DesktopNav';

export const NavBar: React.FC = () => {
  const { isOpen, onToggle } = useDisclosure();
  return (
    <>
      <Box position="sticky" top={0} zIndex={1}>
        <Flex
          as="nav"
          boxShadow="0px 3px 6px #00000029"
          align="center"
          justifyContent="space-around"
          py="16px"
          bg="white"
        >
          <Flex display={{ lg: 'none' }}>
            <IconButton
              aria-label="Toggle navigation"
              variant="none"
              icon={isOpen ? <MdClose /> : <MdMenu />}
              fontSize="40px"
              onClick={onToggle}
              _hover={{ opacity: '0.7' }}
            />
          </Flex>
          <DesktopNav />
        </Flex>
        <Collapse in={isOpen} animateOpacity>
          <MobileNav navItems={headerNavItems} />
        </Collapse>
      </Box>
    </>
  );
};
