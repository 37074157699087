import * as React from 'react';
import { Button, Stack, useBreakpointValue, Link } from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';
import { getConfig } from '../../config';
import CanyouLogo from '../../assets/canyou-blue-logo.svg';
import { headerNavItems } from './navItems';
import { DesktopNavItems } from './DesktopNavItems';

const config = getConfig();
const loginUrl = `${config.platformUrl}/login`;
const signupUrl = `${config.platformUrl}/signup`;

export const DesktopNav: React.FC = () => {
  const isMobileView = useBreakpointValue({ base: true, md: false });
  return (
    <>
      <Stack
        direction="row"
        spacing="55px"
        justifyContent="center"
        alignItems="center"
      >
        <Link as={GatsbyLink} to="/" display="flex">
          {isMobileView ? (
            <CanyouLogo width={90} />
          ) : (
            <CanyouLogo width={180} />
          )}
        </Link>
        <DesktopNavItems navItems={headerNavItems} />
      </Stack>
      <Stack
        spacing={{ base: 0, lg: 3 }}
        direction={{ base: 'column', xl: 'row' }}
        align="center"
      >
        <Button
          as="a"
          size="lg"
          display={{ base: 'none', lg: 'flex' }}
          href={signupUrl}
          variant="outline"
          minW="185px"
        >
          Try for free
        </Button>
        <Button
          as="a"
          href={loginUrl}
          fontSize="md"
          padding={{ base: 0, md: 3 }}
          minW="185px"
          variant="ghost"
          color="brand.blue"
        >
          Log in
        </Button>
      </Stack>
    </>
  );
};
