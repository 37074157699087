import * as React from 'react';
import {
  Flex,
  Text,
  Link as ChakraLink,
  VStack,
  Box,
  Icon,
} from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';
import CanyouLogo from '../assets/canyou-white-logo.svg';
import Apollo from '../assets/canyou-dog.svg';
import { footerNavItems } from './NavBar/navItems';

const LinkHeading = ({ heading }: { heading: string }) => (
  <Text color="brand.white" fontWeight="600" py={2}>
    {heading}
  </Text>
);

const Link = ({
  text,
  href,
  isHeading,
  icon,
  isContact,
}: {
  text: string;
  href: string;
  isHeading?: boolean;
  icon?: React.FunctionComponent<React.SVGAttributes<SVGElement>>;
  isContact?: boolean;
}) =>
  isContact ? (
    <>
      <ChakraLink
        href={href}
        color="brand.white"
        fontWeight={isHeading ? '600' : '400'}
        display="inline-flex"
      >
        {icon && <Icon w={4} h={4} as={icon} mr={2} verticalAlign="text-top" />}
        {text}
      </ChakraLink>
    </>
  ) : (
    <>
      <ChakraLink
        as={GatsbyLink}
        to={href}
        color="brand.white"
        fontWeight={isHeading ? '600' : '400'}
      >
        {icon && <Icon w={4} h={4} as={icon} mr={2} verticalAlign="text-top" />}
        {text}
      </ChakraLink>
    </>
  );

export const Footer: React.FC = () => (
  <>
    <Flex as="footer" bgColor="brand.blue" direction="column" p={8}>
      <Box p={{ base: 2, md: 4, lg: 8 }}>
        <CanyouLogo width={200} />
      </Box>
      <Flex justify="space-between" direction={{ base: 'column', lg: 'row' }}>
        <Flex
          align="baseline"
          flexWrap="wrap"
          direction={{ base: 'column', md: 'row' }}
          p={5}
        >
          {footerNavItems.map(({ label, href, children }) => (
            <VStack
              key={label}
              align="flex-start"
              p={{ base: 2, md: 4, lg: 8 }}
              flex={{ md: '25%', lg: 1 }}
              minW="200px"
            >
              {href ? (
                <Link href={href} text={label} isHeading />
              ) : (
                <LinkHeading heading={label} />
              )}
              {children && (
                <>
                  {children.map((child) => (
                    <Link
                      key={child.label}
                      href={child.href}
                      text={child.label}
                      icon={child.icon}
                      isContact={child.isContact}
                    />
                  ))}
                </>
              )}
            </VStack>
          ))}
        </Flex>
        <Box alignSelf={{ base: 'auto', md: 'flex-end' }} p={5}>
          <Apollo width="150px" />
        </Box>
      </Flex>
      <Text color="brand.white" fontSize="sm" alignSelf="center">
        All rights reserved {new Date().getFullYear()}©
      </Text>
    </Flex>
  </>
);
