import { Flex } from '@chakra-ui/react';
import * as React from 'react';
import { Footer } from './Footer';
import { NavBar } from './NavBar';

export const Layout: React.FC = ({ children }) => (
  <>
    <NavBar />
    <Flex direction="column" as="main" style={{ scrollBehavior: 'smooth' }}>
      {children}
    </Flex>
    <Footer />
  </>
);
